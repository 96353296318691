<template>
  <fki-container
    fixed
    left="0"
    width="100%"
    column
    class="fki-page-news"
  >
    <fki-container-header
      min-height="100px"
      size="30px"
      :color="color"
      :title="title"
    />
    <div class="fki-news-wrapper">
      <ul>
        <li
          v-for="item in news"
          :key="item.identifier"
        >
          <a
            :href="item.link"
            target="_blank"
          >
            <h3
              class="fki-news-item-header"
              v-html="item.title"
            />
            <p v-html="item.summary" />
          </a>
        </li>
      </ul>
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'News',
  components: {
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      title: 'News & Press',
      color: 'blue',
      news: []

    }
  },
  async created () {
    await this.getNews()
  },
  methods: {
    async getNews () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/news')
        this.news = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .fki-page-news{
    top:70px;
 }
.fki-container-header {
   border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
.fki-news-wrapper {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    padding: 0 24px;
    height: 100%;
    margin: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    min-height: 1em;
    justify-content: flex-start;
    flex-direction: column;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width:100%;
      padding: 0 20px;
      li {
        padding-bottom: 15px;
        text-align: left;
        padding-top: 5px;
        font-size: 13px;
        width:100%;
        border-bottom: 1px solid rgba(43, 165, 222, 0.19);
        a {
          text-decoration: none;
          color: #2BA6DE;
          .fki-news-item-header {
            font-size: 18px;
            text-transform: uppercase;
          }
          p {
            font-size:14px;
          }
        }
      }
    }
}
 @media screen and (min-width: 768px) {
 .fki-page-news{
    top:131px;
    bottom:0;
  }
  .fki-news-wrapper {
    width: 60%;
     padding: 0;
  }
 }
</style>
